export const AutomaticMessagesTypes: any = [
  {
    type: "WELCOME", // Willkommen
    translationKey: "messages.auto_message_welcome",
    infoMessageText: "Diese Vorlage wird versendet, sobald du einen Kunden aus dem Onboarding übernimmst.",
  },
  {
    type: "BIRTHDAY_GREETINGS", // Geburtstagsgrüße
    translationKey: "messages.auto_message_birthday",
    infoMessageText: "Diese Vorlage wird versendet, wenn ein Kunde Geburtstag hat",
  },
  {
    type: "APPLICATION_REMINDER", // Antrag: Erinnerung
    translationKey: "messages.auto_message_request_notification",
    infoMessageText:
      "Diese Vorlage wird versendet, wenn dein eingestellter Zeitraum seit der Kundenanmeldung vergangen ist und keine Eingabe unter “Antrag abgegeben” gemacht wurde.",
  },
  {
    type: "APPLICATION_DEADLINE", // Antrag: Fristbalauf
    translationKey: "messages.auto_message_apply_final_term",
    infoMessageText:
      "Diese Vorlage wird versendet, bevor das Datum unter “Antrag gültig bis” in den Antragsinfos des Kunden erreicht ist. (Außer er hat bereits die Theorieprüfung bestanden).",
  },
  {
    type: "THEORY_REMINDER", // Theorie: Erinnerung
    translationKey: "messages.auto_message_theory_memory",
    infoMessageText: "Diese Vorlage wird vor einer Theorielektion an deinen Kunden versendet.",
  },
  {
    type: "TEST_ORDER_AVAILABLE", // Prüfauftrag vorhanden
    translationKey: "messages.auto_message_test_order_available",
    infoMessageText: "Diese Vorlage wird versendet, wenn du in den Antragsinfos beim Kunden eine Eingabe im Feld “Rücklaufnummer” machst. ",
  },
  {
    type: "THEORY_EXAM_AVAILABLE", // Theorieprüfung: Freigegeben
    translationKey: "messages.auto_message_theory_test_approved",
    infoMessageText: "Diese Vorlage wird an alle Teilnehmer einer Theorieprüfung gesendet, sobald du den Termin mit dem “Play-Button” freigegeben hast.",
  },
  {
    type: "THEORY_EXAM_PASSED_SUCCESSFULLY", // Theorieprüfung: Mit Erfolg
    translationKey: "messages.auto_message_theory_test_with_success",
    infoMessageText: "Diese Vorlage wird versendet, wenn dein Kunde eine Theorieprüfung mit Erfolg absolviert hat.",
  },
  {
    type: "THEORY_EXAM_FAILED", // Theorieprüfung: Ohne Erfolg
    translationKey: "messages.auto_message_theory_test_failed",
    infoMessageText: "Diese Vorlage wird versendet, wenn dein Kunde eine Theorieprüfung ohne Erfolg absolviert hat.",
  },
  {
    type: "THEORY_EXAM_DEADLINE", // Theorieprüfung: Fristablauf
    translationKey: "messages.auto_message_theory_expired",
    infoMessageText: "Diese Vorlage wird versendet, bevor die Theorieprüfung deines Kunden ihre Gültigkeit verliert.",
  },
  {
    type: "DRIVING_LESSON_REMINDER", // Praxis: Erinnerung
    translationKey: "messages.auto_message_practical_memory",
    infoMessageText: "Diese Vorlage wird vor einer Fahrstunde an deinen Kunden versendet.",
  },
  {
    type: "PRACTICAL_EXAM_AVAILABLE", // Praxisprüfung: Freigegeben
    translationKey: "messages.auto_message_practical_test_approved",
    infoMessageText: "Diese Vorlage wird an alle Teilnehmer einer Praxisprüfung gesendet, sobald du den Termin mit dem “Play-Button” freigegeben hast.",
  },
  {
    type: "PRACTICAL_EXAM_PASSED_SUCCESSFULLY", // Praxisprüfung: Mit Erfolg
    translationKey: "messages.auto_message_practical_test_successful",
    infoMessageText: "Diese Vorlage wird versendet, wenn dein Kunde eine Praxisprüfung mit Erfolg absolviert hat.",
  },
  {
    type: "PRACTICAL_EXAM_FAILED", // Praxisprüfung: Ohne Erfolg
    translationKey: "messages.auto_message_practical_test_failed",
    infoMessageText: "Diese Vorlage wird versendet, wenn dein Kunde eine Praxisprüfung ohne Erfolg absolviert hat.",
  },
  {
    type: "ADDED_REGISTRATION_DATE", // Antrag abgegeben
    translationKey: "messages.auto_message_added_registration_date",
    infoMessageText: "Diese Vorlage wird versendet, sobald eine Eingabe unter “Antrag abgegeben” gemacht wurde.",
  },
];
