










































import { Vue, Component, Prop } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: { VerticalDivider, FscCard },
})
export default class AutomaticMessageTile extends Vue {
  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop()
  public item!: any;

  public activated = false;

  public onAutomaticMessageStatusChange(e: any) {
    if (this.item.activated === true && !this.item.templateId) {
      this.$toasted.error(String(this.$t("messages.not_selected_automatic_template")));
      this.item.activated = false;
      return;
    }
    this.$emit("activate-auto-messaging", { ...this.item });
  }

  public hideInputField(item: any) {
    let showField: boolean;
    switch (item) {
      case "APPLICATION_REMINDER":
        showField = true;
        break;
      case "APPLICATION_DEADLINE":
        showField = true;
        break;
      case "THEORY_REMINDER":
        showField = true;
        break;
      case "THEORY_EXAM_DEADLINE":
        showField = true;
        break;
      case "DRIVING_LESSON_REMINDER":
        showField = true;
        break;
      default:
        showField = false;
        break;
    }
    return showField;
  }
}
