




































import { Component, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import AutomaticMessageTile from "@/components/AutomaticMessageTile.vue";
import { AutomaticMessagesTypes } from "@/constants/AutomaticMessagesTypes";
import { namespace } from "vuex-class";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

const MessageModule = namespace("message-templates");
const AutomaticCommunication = namespace("automatic-messages");
@Component({
  components: {
    FscPageHeader,
    AutomaticMessageTile,
    SaveButton,
    AbortButton,
  },
})
export default class AutomaticMessages extends Vue {
  public name = "AutomaticMessages";

  @MessageModule.Action("getTemplatesAllAction")
  protected findMessageTemplates!: any;

  @MessageModule.Getter("getTemplates")
  protected getMessageTemplates!: any;

  @MessageModule.Getter("getIsLoading")
  protected getMessageTemplatesIsLoading!: any;

  @AutomaticCommunication.Action("findAll")
  protected findMessageStatuses!: any;

  @AutomaticCommunication.Mutation("SET_DATA_LIST")
  protected setMessageStatuses!: any;

  @AutomaticCommunication.Getter("getDataList")
  protected getMessageStatuses!: any;

  @AutomaticCommunication.Action("update")
  protected updateMessageStatuses!: any;

  @AutomaticCommunication.Action("create")
  protected submitAutomaticMessages!: any;

  @AutomaticCommunication.Getter("getSuccess")
  protected success!: any;

  @AutomaticCommunication.Getter("getIsLoading")
  protected getMessageStatusesIsLoading!: any;

  public messages: Array<any> = [];

  public mounted() {
    this.fetchDate();
  }

  public async fetchDate() {
    await this.findMessageTemplates();
    await this.findMessageStatuses({ resource: "automatic-messages" });

    this.messages = AutomaticMessagesTypes.map((automaticMessage: any) => {
      const findMessageStatus = this.getMessageStatuses.find((messageStatus: any) => messageStatus.automaticMessageGroupEnum === automaticMessage.type);
      const mergedMessage = { ...automaticMessage, ...findMessageStatus };
      return {
        ...mergedMessage,
        templateOptions: this.getMessageTemplates.map((template: any) => {
          const { name, id } = template;
          return { name, id };
        }),
        title: this.$t(mergedMessage.translationKey),
      };
    });
  }

  protected confirmOperation(automaticMessageStatus: any) {
    if (automaticMessageStatus) {
      this.$toasted.success(String(this.$t("messages.message_activated")));
    } else {
      this.$toasted.success(String(this.$t("messages.message_deactivated")));
    }
  }

  protected onRequestSend(args: any) {
    console.log("REQ");
    // const { type, activated, templateId, id, period } = args;
    // this.updateMessageStatuses({
    //   resource: "automatic-messages",
    //   id,
    //   data: {
    //     id,
    //     automaticMessageGroupEnum: type,
    //     activated,
    //     templateId: templateId,
    //     period: period,
    //   },
    // })
    //   .then(() => {
    //     this.confirmOperation(activated);
    //   })
    //   .catch(() => {
    //     this.confirmOperation(activated);
    //   });
  }

  public async onSubmit() {
    const messageDto = this.messages.map((message: any) => {
      return {
        activated: message.activated,
        automaticMessageGroupEnum: message.automaticMessageGroupEnum,
        id: message.id,
        period: message.period,
        templateId: message.templateId,
      };
    });

    await this.submitAutomaticMessages({
      resource: "automatic-messages",
      data: messageDto,
    });

    if (this.success) {
      this.fetchDate();
    }
  }
}
